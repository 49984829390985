import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';
import { IoIosAddCircleOutline } from "react-icons/io";
import '../../../assets/css/DataAnalytics.css';
import '../../../assets/css/ManageUsers.css';
import AppLoadingComponents from "../../loadingComponents/AppLoadingComponent";
import GenericSuccessToaster from "../../toasterscomponents/GenericSuccessToaster";
import ItemAddedToaster from "../../toasterscomponents/checklistToasters/ItemAddedToaster";

const AddMSPowerBIComponent = () => {
    const [powerBiName, setPowerBiName] = useState('');
    const [powerBiLink, setPowerBiLink] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [addedSuccessfully, setIsAddedSuccessfully] = useState(false);
    const [showReseted, setShowReseted] = useState(false);
    const [showTextEmptyNameError, setShowTextEmptyNameError] = useState(false);
    
    const navigate = useNavigate();

    const handleNavigateBack = () => {
        navigate('/data-analytics');
    };

    const handleSubmit = async () => {
        if (!powerBiName || !powerBiLink) {
            setShowTextEmptyNameError(true);
            return;
        }

        setIsSubmitting(true);
        setShowTextEmptyNameError(false);

        try {
            const response = await axios.post('/api/add-ms-Power-Bi-Report', {
                power_bi_name: powerBiName,
                power_bi_link: powerBiLink
            });

            if (response.status === 201) {
                setPowerBiName('');
                setPowerBiLink('');
                setIsAddedSuccessfully(true);

                setTimeout(() => {
                    setIsAddedSuccessfully(false);
                    navigate('/data-analytics');
                }, 2000);
            }
        } catch (error) {
            console.error('Error submitting Power BI link:', error);
            // Handle error cases here
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleReset = () => {
        setPowerBiName('');
        setPowerBiLink('');
        setShowTextEmptyNameError(false);
        setShowReseted(true);

        setTimeout(() => {
            setShowReseted(false);
        }, 2000);
    };

    return (
        <div className="component-container">
            <div className="create-checklist-component-container">
                <div className="create-company-text-container">
                    <div>
                        <h1>Add MS Power BI Report Links</h1>
                        
                    </div>
                    <div>
                        <img src={blueKbcLogo} alt="KBC logo" className="side-menu-logo" />
                    </div>
                </div>

                <div className="navigate-back-button-main-container">
                    <button className="navigate-back-button" onClick={handleNavigateBack}>
                        Back
                    </button>
                </div>

                <div className="view-checklist-table-container">
                    <div className="view-checklist-table-table-container">
                        <table className="view-checklist-table">
                            <colgroup>
                                <col style={{width: "20%"}} />
                                <col style={{width: "60%"}} />
                                <col style={{width: "10%"}} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="table-header">MS Power BI Name</th>
                                    <th className="table-header">MS Power BI Link</th>
                                    <th className="table-header">Create</th>
                                </tr>
                            </thead>
                            <tbody className="view-table-body">
                                <tr>
                                    <td>
                                        <input 
                                            className="add-ms-power-bi-input-power-bi-name"
                                            placeholder="Create MS Power BI Name"
                                            value={powerBiName}
                                            onChange={(e) => setPowerBiName(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input 
                                            className="add-ms-power-bi-input-power-bi-link"
                                            placeholder="Enter MS Power BI link"
                                            value={powerBiLink}
                                            onChange={(e) => setPowerBiLink(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        
                                            <IoIosAddCircleOutline
                                              onClick={handleSubmit}
                                              className="assign-user-assign-icon" 
                                              aria-label="Add Power BI report"
                                              style={{ cursor: 'pointer' }}
                                            />
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {showTextEmptyNameError && (
                        <p className="error-text">Error! Empty fields, please fill in all fields.</p>
                    )}

                    <div className="assign-user-button-container-container">
                        <button 
                            className="assign-user-button" 
                            type="button" 
                            onClick={handleReset}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </div>

            {showReseted && <ItemAddedToaster item={'Cleared'} />}
            {addedSuccessfully && (
                <GenericSuccessToaster
                    heading={'Added MS Power BI Report'}
                    message={'Redirecting your page'}
                />
            )}
            {isSubmitting && (
                <AppLoadingComponents loading_title_name={'Processing MS Power BI Report...'} />
            )}
        </div>
    );
};

export default AddMSPowerBIComponent;