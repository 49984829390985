// ------------------- React Components --------------------
import React, { useState, useEffect, lazy } from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';
import {Helmet} from 'react-helmet'
// -------------- Application Components ----------------------------
import LoadingComponents from './components/loadingComponents/LoadingComponents';
import DataAnalytics from './components/DataAnalyticsComponents/DataAnalytics.js/DataAnayltics';
import AddMSPowerBIComponent from './components/DataAnalyticsComponents/AddMSPowerBiLink.js/AddMSPowerBIComponent';
import ViewPowerBiLinks from './components/DataAnalyticsComponents/ViewPowerBiLink/ViewPowerBiLink';
import AddDepartmentComponent from './components/manageuserscomponents/addDepartmentComponent/AddDepartmentComponent';
import EditChecklistToaster from './components/checklistmanagementcomponents/editchecklistcomponents/ToasterEditChecklist';

// Lazy load components

// ---------- Header Components ----------------------
const Header = lazy(() => import('./components/Header'));
const UserInfoHeader = lazy(() => import('./components/UserInfoHeader'));

// ------------------- Login component ---------------
const Login = lazy(() => import('./components/Login'));

// -------------------- Dashbaord Component -------------------------
const Dashboard = lazy(() => import('./components/dashboardcomponents/Dashboard'));

// ------------------------- Checklist Management Components ------------------------

const ChecklistManagement = lazy(() => import('./components/checklistmanagementcomponents/Checklist'));

// Checklists
const ViewChecklist = lazy(() => import('./components/checklistmanagementcomponents/viewchecklistcomponents/ViewCheckList'));
const AddQuestionsCreateChecklist = lazy(() => import('./components/checklistmanagementcomponents/createchecklistcomponents/AddQuestionsCreateChecklist'));
const ChecklistFillIn = lazy(() => import('./components/checklistmanagementcomponents/fillinchecklistcomponents/ChecklistFillIn'));

// ------------------------ People Management COmponents ------------------


const ManageUsers = lazy(() => import('./components/manageuserscomponents/ManagerUsers'));

// People Management 
const CreateCompanyComponent = lazy(() => import('./components/manageuserscomponents/createcompanycomponents/CreateCompanyComponent'));
const CreateUserComponent = lazy(() => import('./components/manageuserscomponents/createusercomponents/CreateUserComponent'));
const ViewUsersComponent = lazy(() => import('./components/manageuserscomponents/viewuserscomponents/Viewuserscomponent'));
const AssignUserComponent = lazy(() => import('./components/manageuserscomponents/assignuserscomponents/AssignUserComponent'));

// ------------------- Data Analytics Components ------------------------
const DataAnalyticsAssignUser = lazy(() => import('./components/DataAnalyticsComponents/dataanalyticscomponent/DataAnalyticsAssign'));
const ProfileComponents = lazy(() => import('./components/Profilecomponents/ProfileComponents'));
const HelpComponents = lazy(() => import('./components/helpcomponents/HelpComponents'));

const RoleBasedRoute = lazy(() => import('./components/utils/Authentication/RoleBasedRoute'));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const authStatus = JSON.parse(sessionStorage.getItem('isAuthenticated'));
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (authStatus && user && user.userRole) {
      setIsAuthenticated(authStatus);
      setUserRole(user.userRole);
    } else {
      if (window.location.pathname !== '/') {
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('user');
        setIsAuthenticated(false);
        setUserRole('');
        window.location.replace('/');
      }
    }
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const handleAuthentication = (status, user) => {
    setIsAuthenticated(status);
    if (status && user && user.userRole) {
      setUserRole(user.userRole);
      sessionStorage.setItem('isAuthenticated', JSON.stringify(status));
      sessionStorage.setItem('user', JSON.stringify(user));
    } else {
      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('user');
      setUserRole('');
      setIsAuthenticated(false);
    }
  };

  return (

    <div className='main-container'>

<BrowserRouter className='main-div'>
      
      <Suspense fallback={<LoadingComponents />}>
        <div className="application-component-container">
          <div className="application-container">
            {isAuthenticated ? (
              <div className="application-layout">
                <div className={`app-container ${isCollapsed ? 'header-collapsed' : ''}`}>
                  <Header
                    className='header-container'
                    isAuthenticated={isAuthenticated}
                    onAuthentication={handleAuthentication}
                    isCollapsed={isCollapsed}
                    toggleCollapse={toggleCollapse}
                  />
                </div>
                <div className="main-content-container">
                  <div className="app-user-info-header-container">
                    <UserInfoHeader className='user-info-header-container' isAuthenticated={isAuthenticated} onAuthentication={handleAuthentication} />
                  </div>
                  <div className="app-routes-main-container">
                    <Routes>
                      <Route
                        path="/dashboard"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <Dashboard />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path="/checklistmanagement"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <ChecklistManagement />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path="/createchecklist"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <AddQuestionsCreateChecklist />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/editchecklist"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <EditChecklistToaster />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/checklistfillin"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <ChecklistFillIn />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path="/viewchecklist"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ViewChecklist />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/managerusers"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ManageUsers />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path='/createcompany'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                            <CreateCompanyComponent />
                          </RoleBasedRoute>
                        }
                      />


                    <Route
                        path='/people-management/add-department'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                            <AddDepartmentComponent />
                          </RoleBasedRoute>
                        }
                      />
                      
                      <Route
                        path='/createuser'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                            <CreateUserComponent />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path='/viewusers'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ViewUsersComponent />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path='/assignusers'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <AssignUserComponent />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path='/data-analytics-assign-user'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <DataAnalyticsAssignUser />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/data-analytics"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <DataAnalytics />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/data-analytics-add-MS-Power-Bi-Link"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <AddMSPowerBIComponent />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/data-analytics-view-MS-Power-Bi-Link"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ViewPowerBiLinks />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/profile"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <ProfileComponents />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path="/help"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <HelpComponents />
                          </RoleBasedRoute>
                        }
                      />
                      <Route path="*" element={<Navigate to="/dashboard" />} />
                    </Routes>
                  </div>
                </div>
              </div>
            ) : (
              <Routes>
                <Route path="/" element={<Login onAuthentication={handleAuthentication} />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            )}
          </div>
        </div>
      </Suspense>
    </BrowserRouter>

    </div>
    
  );
}

export default App;
