/* ----------- React Component --------------- */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../utils/apiConfig";

/*----------  Styles -----------*/

// Styles - Icons 
import { MdDeleteOutline } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; 
import ToasterChecklistCreated from "../../toasterscomponents/ToasterChecklistCreated.js";
import ItemAddedToaster from "../../toasterscomponents/checklistToasters/ItemAddedToaster.js";
import ItemRemovedToaster from "../../toasterscomponents/checklistToasters/ItemRemovedToaster.js";

import { TiTick } from "react-icons/ti";

import { MdOutlineCancel } from "react-icons/md";

import { CiEdit } from "react-icons/ci";
import GenericSuccessToaster from "../../toasterscomponents/GenericSuccessToaster.js";

const EditChecklistToaster = ({ formName, checklistUniqueIdentifier }) => {

    //
    //
    const [viewSectionAInformation, setViewSectionAInformation] = useState(true);
    const [viewSectionBCategories, setViewSectionBCategories] = useState(false);
    const [viewSectionCCreateQuestions, setViewSectionCCreateQuestions] = useState(false);

    //
    const [selectedChecklists, setSelectedChecklists] = useState([]);

    // Add new state variables for category editing
    const [editingCategory, setEditingCategory] = useState(null);
    const [editedCategoryName, setEditedCategoryName] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");

    const [checklistName, setChecklistName] = useState("");
    const [visibleOptions, setVisibleOptions] = useState(3);
    
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [errors, setErrors] = useState([]);
    const [customColumns, setCustomColumns] = useState([]);
    const [newColumnName, setNewColumnName] = useState("");

    // Renders Question Types 
    const [questionTypes] = useState(['Date', 'Drop Down','File', 'Free Text','Time','Short Text','Radio']);
    const [questionBankData, setQuestionBankData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState("");

    const [categoryError, setCategoryError] = useState("");
    const [optionError, setOptionError] = useState("");

    const [showSuccess, setShowSuccess] = useState(false);
    const [showCategoryAdded, setShowCategoryAdded] = useState(false);
    const [showRowAdded, setShowRowAdded] = useState(false);
    const [showRowRemoved, setShowRowRemoved] = useState(false);
    const [showOptionAdded, setShowOptionAdded] = useState(false);

    const [showEditedCategory, setShowEditedCategory] = useState(false);
    const [showCancelEditedCategory, setShowCancelEditedCategory] = useState(false);

    const [checklists, setChecklists] = useState([]);

    //

    const [selectedChecklist, setSelectedChecklist] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    
    const [editError, setEditError] = useState("");

    //

    // State Management - Loading spinner 
  const [isLoading, setIsLoading] = useState(true);

  
  //
  const handleViewSectionAInformation = () => {
    setViewSectionAInformation((prevState) => !prevState);
  };

  const handleViewSectionBCategories = () => {
    setViewSectionBCategories((prevState) => !prevState);
  };

  const handleViewSectionCCreateQuestions = () => {
    setViewSectionCCreateQuestions((prevState) => !prevState);
  };



  // Function to fetch checklist data
  const fetchChecklistData = async (checklistId) => {
    console.log("Fetching checklist data for checklistId:", checklistId);
    setIsLoading(true);
    
    try {
        const response = await axios.get(`${API_BASE_URL}/question-bank/${checklistId}`);
        console.log("Response received:", response);
        
        if (response.data && response.data.length > 0) {
            console.log("Data found:", response.data);
            
            // Update state with fetched data
            setQuestionBankData(response.data);
            setChecklistName(response.data[0].checklist_name);
            
            // Extract and set categories
            const uniqueCategories = [...new Set(response.data.map(item => item.category))];
            console.log("Unique categories extracted:", uniqueCategories);
            setCategories(uniqueCategories.filter(Boolean));

            // Find maximum number of options used
            const maxOptions = Math.max(...response.data.map(row => {
                let maxUsed = 0;
                for (let i = 1; i <= 15; i++) {
                    if (row[`option_${i}`]) maxUsed = i;
                }
                return maxUsed;
            }));
            console.log("Max options determined:", maxOptions);
            setVisibleOptions(Math.max(maxOptions, 3)); // Ensure at least 3 options are visible
            
            setIsEditing(true);
        } else {
            console.log("No data found for this checklist");
            setEditError("No data found for this checklist");
        }
    } catch (error) {
        console.error('Error fetching checklist data:', error);
        setEditError(`Error loading checklist: ${error.message}`);
    } finally {
        console.log("Finished fetching data");
        setIsLoading(false);
    }
};


// Handle edit button click
const handleEditClick = () => {
    if (!selectedChecklist) {
        setEditError("Please select a checklist to edit");
        return;
    }
    setEditError("");
    fetchChecklistData(selectedChecklist);
};

  //

    

    // edit category 
    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setEditedCategoryName(category);
    };

    const handleSaveEditedCategory = () => {
        if (editedCategoryName.trim() === "") {
            setCategoryError("Category name cannot be empty");
            return;
        }

        if (categories.includes(editedCategoryName) && editedCategoryName !== editingCategory) {
            setCategoryError("This category name already exists");
            return;
        }

        setCategories(prevCategories => 
            prevCategories.map(cat => 
                cat === editingCategory ? editedCategoryName : cat
            )
        );

        // Update category in questionBankData
        setQuestionBankData(prevData =>
            prevData.map(row => ({
                ...row,
                category: row.category === editingCategory ? editedCategoryName : row.category
            }))
        );

        setEditingCategory(null);
        setEditedCategoryName("");
        setCategoryError("");
        setShowEditedCategory(true)
    };

    const handleCancelEdit = () => {
        setEditingCategory(null);
        setEditedCategoryName("");
        setCategoryError("");
        setShowCancelEditedCategory(true)
    };


    //
    
    const navigate = useNavigate();

    useEffect(() => {
        fetchQuestionBankData();
        
        //
        // Initialize with General category
        setCategories(['General']);

    }, []);

    //
    
    // fetch checklists 

    useEffect(() => {

        const fetchChecklists = async () => {
    
          setIsLoading(true)
    
          try {
    
            const response = await axios.get('/api/rows/app_checklists');
            setChecklists(response.data);
    
          } catch (error) {
    
            console.error('Error fetching companies:', error);
    
          } finally {
    
            setIsLoading(false)
    
          }
        };
    
        fetchChecklists();
      }, []);
    


    //

    useEffect(() => {
        const timers = [];

        
        if (showCategoryAdded) {
            timers.push(setTimeout(() => setShowCategoryAdded(false), 3000));
        }
        if (showRowAdded) {
            timers.push(setTimeout(() => setShowRowAdded(false), 3000));
        }
        if (showRowRemoved) {
            timers.push(setTimeout(() => setShowRowRemoved(false), 3000));
        }
        if (showOptionAdded) {
            timers.push(setTimeout(() => setShowOptionAdded(false), 3000));
        }

        if (showEditedCategory){
            timers.push(setTimeout(() => setShowEditedCategory(false), 3000));
        }

        if (showCancelEditedCategory) {
            timers.push(setTimeout(() => setShowCancelEditedCategory(false), 3000));
        }

        return () => timers.forEach(timer => clearTimeout(timer));
    }, [showSuccess, showCategoryAdded, showRowAdded, showRowRemoved, showOptionAdded , showEditedCategory, showCancelEditedCategory]);

    const fetchQuestionBankData = async () => {

        try {
            const columnsResponse = await axios.get(`${API_BASE_URL}/columns/Question_Bank`);
            const fetchedColumns = columnsResponse.data;
            setColumns(fetchedColumns);
            
            
            // Remove this line:
            // setVisibleColumnNames(['Remove', ...visibleColumns.map(col => col.name)]);

        } catch (error) {
            console.error('Error fetching Question Bank data:', error);
        }
    };

    const handleNavigateBack = () => {
        navigate('/checklistmanagement');
    };

    const handleInputChange = (rowIndex, columnName, value) => {
        const updatedData = [...questionBankData];

        if (rowIndex < 5 && (columnName === 'question_name' ||columnName === 'question_type' || columnName === 'is_mandatory' || columnName === 'category')) {
            return;
        }

        if (columnName === 'question_type') {
            // Remove this condition to allow changes in the first row
            // if (rowIndex === 0) {
            //     return;
            // }
            // Prevent modifying question type and mandatory status for predefined questions
        
    
            // Clear options when changing from 'Radio' or 'Drop Down' to another type
            if (value !== 'Radio' && value !== 'Drop Down') {
                for (let i = 1; i <= 15; i++) {
                    updatedData[rowIndex][`option_${i}`] = '';
                }
            }
            // Clear file_upload when changing from 'File' to another type
            if (value !== 'File') {
                updatedData[rowIndex]['type_file_upload'] = '';
            }
        }
        
        updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
        setQuestionBankData(updatedData);
    };

    const generateUniqueId = (prefix) => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
        const randomNumber = Math.floor(Math.random() * 10);
        return `${prefix}${year}${month}${day}${hours}${minutes}${randomLetter}${randomNumber}`;
    };

    const handleAddRow = () => {

        
        const newRow = visibleColumns.reduce((acc, column) => {
            if (column.name === 'question_id') {
                acc[column.name] = generateUniqueId('QID');
            } else if (column.name === 'checklist_id') {
                acc[column.name] = checklistUniqueIdentifier;
            } else if (column.name === 'checklist_name') {
                acc[column.name] = formName;
            } else if (column.name === 'question_type') {
                acc[column.name] = 'Radio';
            } else {
                acc[column.name] = '';
            }
            return acc;
        }, {});

        setShowRowAdded(true)
        setQuestionBankData([...questionBankData, newRow]);

    };

    const handleAddColumn = () => {
        if (newColumnName && !customColumns.includes(newColumnName)) {
            setCustomColumns(prevColumns => [...prevColumns, newColumnName]);
            setNewColumnName("");
            setQuestionBankData(prevData => 
                prevData.map(row => ({
                    ...row,
                    [newColumnName]: ''
                }))
            );
        }
    };

    

    const handleSubmit = async () => {

        setSubmitStatus('loading');
        setErrors([]);
        
        let newErrors = [];
      
        if (!checklistName.trim()) {
            newErrors.push("Empty Field -  Checklist name cannot be empty.");
        }
      
        if (questionBankData.length === 0) {
            newErrors.push("No Questions - Add at least one question row to the checklist.");
        }
    
        const hasMandatoryQuestion = questionBankData.some(row => row.is_mandatory === 'Yes');
        if (!hasMandatoryQuestion) {
            newErrors.push("Mandatory Questions - At least one question must be mandatory.");
        }
      
        if (newErrors.length > 0 || !validateData()) {
            setErrors(prevErrors => [...prevErrors, ...newErrors]);
            window.scrollTo(0, 0);
            setSubmitStatus('idle');
            return;
        }
      
        try {
            const response = await axios.put(`${API_BASE_URL}/update-checklist/${selectedChecklist}`, {
                formName: checklistName,
                formSections: categories.map(category => ({ name: category, inputField: 'custom' })),
                questionBankData: questionBankData
            });

            console.log('Checklist updated successfully:', response.data);
            setShowSuccess(true);
            setSubmitStatus('success');

            setTimeout(() => {
                setShowSuccess(false);
                navigate('/checklistfillin');
            }, 2000);

        } catch (error) {
            console.error('Error updating data:', error);
            setErrors(prevErrors => [...prevErrors, `Error updating checklist: ${error.message}`]);
            setSubmitStatus('error');
        }
    };

    const handleAddCategory = () => {

        if (newCategory.trim() === "") {
            setCategoryError("Empty Field - To save category, please enter a category name.");
            return;
        }

        if (!categories.includes(newCategory)) {
            setCategories([...categories, newCategory]);
            setNewCategory("");
            setShowCategoryAdded(true);
            setCategoryError("");  // Clear any existing error
        } else {
            setCategoryError("Duplicate Entry - This category already exists.");
        }
    };

    const handleClear = () => {
        setChecklistName("");
        setQuestionBankData([]);
        setCategories([]);
        setNewCategory("");
        setCategoryError("");
        setErrors([]);
    };

    const handleRemoveRow = (rowIndex) => {

        if (rowIndex < 5) {
            // Show error or notification that predefined questions cannot be removed
            setErrors(prev => [...prev, "Error - Predefined questions cannot be removed"]);
            return;
        }

        setShowRowRemoved(true)

        

        const updatedData = questionBankData.filter((_, index) => index !== rowIndex);
        setQuestionBankData(updatedData);
    };

    const isCustomColumn = (columnName) => customColumns.includes(columnName);

    // Filter out the columns we don't want to display
    const visibleColumns = [
        ...columns.filter(column => 
            !['checklist_id', 'checklist_name', 'question_id'].includes(column.name)
        ),
        ...customColumns.map(name => ({ name }))
    ];

    const validateData = () => {
        const newErrors = [];
        questionBankData.forEach((row, index) => {
            if (!row.question_name || row.question_name.trim() === '') {
                newErrors.push(`Error - Question Name : Question name cannot be empty.`);
            }
            if (row.question_type === 'Radio' || row.question_type === 'Drop Down') {
                const options = [row.option_1, row.option_2, row.option_3, row.option_4].filter(Boolean);
                if (options.length === 0) {
                    newErrors.push(`Error - ${row.question_type} options : ${row.question_type} question must have at least one option.`);
                }
            }
        });
        setErrors(newErrors);
        return newErrors.length === 0;
    };

    const handleAddOption = () => {
        if (visibleOptions < 15) {
            setVisibleOptions(prevVisible => prevVisible + 1);
            setShowOptionAdded(true);
            setOptionError(""); // Clear any existing error
        } else {
            setOptionError("Error: Maximum of 15 options allowed.");
        }
    };

    const renderOptionInputs = (row, rowIndex) => {
        return Array.from({ length: visibleOptions }, (_, i) => {
            const optionName = `option_${i + 1}`;
            return (
                <td key={optionName}>
                    <input
                        type="text"
                        value={row[optionName] || ''}
                        onChange={(e) => handleInputChange(rowIndex, optionName, e.target.value)}
                        disabled={row['question_type'] !== 'Radio' && row['question_type'] !== 'Drop Down'}
                        placeholder={row['question_type'] !== 'Radio' && row['question_type'] !== 'Drop Down' ? 'Options not applicable' : `Enter option ${i + 1}`}
                    />
                </td>
            );
        });
    };

    const visibleColumnNames = [

        'Remove',
        'question_type',
        'question_name',
        'category',
        'is_mandatory',
        'expires',
        ...Array.from({ length: visibleOptions }, (_, i) => `option_${i + 1}`),
        
    ];

    return (
        <div className="add-question-custom-field-main-component-container">
            <div className="add-question-create-questions-checklist-custom-field-component-container">
                <div className="create-questions-custom-field-container">
                    <div className="add-question-view-checklist-component-container">
                        {/* Header and navigation */}
                        <div className="add-question-view-checklist-header-container">
                            <div>
                                <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
                            </div>
                            <div className="navigate-back-button-container">
                                <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
                            </div>
                        </div>

                        
                        
                        {/* Step 2 - Question Bank */}
                        <div className="add-question-view-checklist-header-step-container">
                            
                            <div>

                                <div>
                                    <h2>Edit Checklist</h2>
                                </div>

                                <div>
                                    
                                    <p>To edit checklists that haven't been filled in. </p>

                                    <p className="secondary-color-paragraph">To complete each section, click on the ‘Maximize’ button to expand the section.</p>
                                
                                </div>
                                
                            </div>
                           
                        </div>
                        {/* Section A - Information container */}
                        <div className="create-checklist-section-a-information-main-container">

                            <div className="create-checklist-section-container-main-container">

                                <div className="create-checklist-section-container">

                                    <div>
                                        <div>
                                            <h3>Section A - Information</h3>
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <button className="confirm-button" onClick={handleViewSectionAInformation}>
                                                {viewSectionAInformation ? 'Minimize' : 'Maximize'}
                                            </button>

                                            
                                        </div>
                                    </div>

                                </div>

                            </div>

                            {viewSectionAInformation && (

                                //Checklist Information  
                                <div className="create-checklist-section-a-information-container">

                               

                                <div className="create-checklist-section-a-information-container-container">

                                    <div className="edit-checklist-section-a-information-checklist-name-container">

                                        <div>
                                            <h4>Checklist Name</h4>

                                            <p>Select the checklist that you want to edit.</p>
                                        </div>

                                        <div className="add-question-view-checklist-category-container">
                                    
                                            <div>
                                                <select
                                                    value={selectedChecklist}
                                                    onChange={e => setSelectedChecklist(e.target.value)}
                                                    disabled={isEditing}
                                                >
                                                    <option value="">-- Select Checklist --</option>
                                                    {checklists.map(checklist => (
                                                        <option key={checklist.checklist_id} value={checklist.checklist_id}>
                                                            {checklist.checklist_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {editError && <div className="error-text">{editError}</div>}

                                            <div>
                                                <p style={{ fontStyle: 'italic' }}>Click "Edit Checklist" to proceed</p>
                                            </div>

                                                <div>
                                                    <button 
                                                        className="confirm-button" 
                                                        onClick={handleEditClick}
                                                        disabled={isEditing || isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <>
                                                                <FaSpinner className="fa-spin" />
                                                                <span>Loading...</span>
                                                            </>
                                                        ) : (
                                                            'Edit Checklist'
                                                        )}
                                                    </button>
                                                </div>
                                            </div>

                                    </div>

                                    

                                    
                                </div>

                            </div>
                                
                            )}

                            
                            
                        </div>

                        {/* Section B - Categories  */}
                        <div className="create-checklist-section-a-information-main-container">

                            <div className="create-checklist-section-container-main-container">

                                <div className="create-checklist-section-container">

                                    <div>
                                        <div>
                                            <h3>Section B - Categories</h3>
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <button className="confirm-button" onClick={handleViewSectionBCategories}>
                                                {viewSectionBCategories ? 'Minimize' : 'Maximize'}
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            {viewSectionBCategories && (

                                //Categories Information 
                            <div className="create-checklist-section-a-information-container">

                            

                            <div className="create-checklist-section-a-information-container-container">
                                {categoryError && <p className="error-text">{categoryError}</p>}
                            </div>

                            <div className="create-checklist-section-a-information-container-container">

                                <div className="create-checklist-section-a-information-checklist-category-container">

                                    <div>

                                        <div>
                                            <p className="category-secondary-color">Create Question Category</p>
                                            <p>To create a category, enter the category name in the input field and click the "Save Category" button to save it.<br/><br/> Saved categories will appear in the saved categories box </p>
                                            
                                        </div>
                                        
                                    </div>

                                    <div className="create-checklist-section-b-information-checklist-category-input-button-container">
                                        <div>
                                                <input 
                                                    className="create-checklist-questions-form-name-input"
                                                    placeholder="Enter Category name"
                                                    value={newCategory}
                                                    onChange={(e) => {
                                                        setNewCategory(e.target.value);
                                                        setCategoryError("");
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <button type="button" onClick={handleAddCategory} className="confirm-button">Save Category</button>
                                            </div>

                                        </div>

                                   

                                </div>

                                {/* Saved Categoires */}
                                <div className="create-checklist-section-a-information-checklist-saved-category-container">

                                <div>

                                    <div>

                                        <h4>Saved Categories</h4>

                                        <p>To edit a category , click on the pen icon. <br/><br />Please remember to save your changes by clicking the green tick.</p>


                                    </div>
                                    
                                </div>

                                <div className="create-checklist-section-a-information-checklist-saved-category-list-container">
                                
                                    
                                {categories.length === 0 ? (
                                        <div className="add-question-view-checklist-no-categories-message">
                                            <p style={{fontStyle : 'italic'}} >No categories have been added yet.</p>
                                        </div>
                                    ) : (
                                        
                                        categories.map((category, index) => (
                                            <div key={index} className="add-question-view-checklist-category-list-item-main-container">
                                                {editingCategory === category ? (
                                                    <div className="add-question-view-checklist-category-list-item-container">
                                                        <input
                                                            value={editedCategoryName}
                                                            onChange={(e) => setEditedCategoryName(e.target.value)}
                                                            className="add-question-view-checklist-category-list-input"
                                                        />
                                                        <div className="add-question-view-checklist-icon-container">
                                                            <TiTick className="add-question-view-checklist-tick-icon-container" onClick={handleSaveEditedCategory} />
                                                            <MdOutlineCancel className="add-question-view-checklist-cancel-icon-container" onClick={handleCancelEdit}/>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="add-question-view-checklist-category-list-item-container">
                                                        <div>
                                                            <span>{category}</span>
                                                        </div>
                                                        <div>
                                                            <CiEdit className="add-question-view-checklist-category-list-item-edit-icon" onClick={() => handleEditCategory(category)} />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    )}
                                </div>

                                    

                                </div>

                                
                            </div>
                            

                        </div>
                            )}

                        </div>

                        <div className="create-checklist-section-a-information-main-container">

                            <div className="create-checklist-section-container-main-container">

                                <div className="create-checklist-section-container">

                                    <div>
                                        <div>
                                            <h3>Section C - Edit Questions</h3>
                                        </div>
                                    </div>

                                    <div>
                                        <div>
                                            <button className="confirm-button" onClick={handleViewSectionCCreateQuestions}>
                                                {viewSectionCCreateQuestions ? 'Minimize' : 'Maximize'}
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            {viewSectionCCreateQuestions && (

                                //Checklist Information  
                                
                        <div className="add-question-view-fillin--checklist-table-container">
                        <div className="add-question-view-checklist-table-table-container">
                            <table className="create-questions-view-fillin-checklist-table">
                                
                                <colgroup>{/* Remove whitespace between col elements */}
                                    <col style={{width: "50px"}}/>{/* Delete icon column */}
                                    <col style={{width: "150px"}}/>{/* question_type column */}
                                    <col style={{width: "300px"}}/>{/* question_name column */}
                                    <col style={{width: "150px"}}/>{/* category column */}
                                    <col style={{width: "150px"}}/>{/* is_mandatory column */}
                                    <col style={{width: "150px"}}/>{/* expires column */}
                                    {Array.from({ length: visibleOptions }, (_, i) => (
                                        <col key={i} style={{width: "150px"}}/> // option columns
                                    ))}
                                </colgroup>

                                <thead>
                                    <tr>
                                        {visibleColumnNames.map((columnName, index) => (
                                            <th key={index} className="table-header">
                                                {columnName === 'Remove' ? '' : columnName}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody className="view-table-body">
{questionBankData.length === 0 ? (
    <tr>
        <td colSpan={visibleColumnNames.length}>No checklist selected. In Section A select a checklist and click 'Edit Checklist' to add a question.</td>
    </tr>
) : (
    questionBankData.map((row, rowIndex) => (
        <tr key={rowIndex}>

            <td>
                <button onClick={() => handleRemoveRow(rowIndex)} className="remove-row-button">
                    <MdDeleteOutline className='delete-checklist-submition-icon' />
                </button>
            </td>

            <td>
                <select
                    value={row['question_type'] || 'Radio'}
                    onChange={(e) => handleInputChange(rowIndex, 'question_type', e.target.value)}
                    
                >
                    {questionTypes.map((type, index) => (
                        <option key={index} value={type}>{type}</option>
                    ))}
                </select>
            </td>

            <td>
                <input
                    type="text"
                    value={row['question_name'] || ''}
                    onChange={(e) => handleInputChange(rowIndex, 'question_name', e.target.value)}
                    placeholder="Enter question name"
                    className="create-questions-form-question-input-column"
                    
                />
            </td>

            <td>
                <select
                    value={row['category'] || ''}
                    onChange={(e) => handleInputChange(rowIndex, 'category', e.target.value)}
                >
                    <option value="">Select a category</option>
                    {categories.map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                    ))}
                </select>
            </td>
            
            <td>

                <select
                    value={row['is_mandatory'] || 'No'}
                    onChange={(e) => handleInputChange(rowIndex, 'is_mandatory', e.target.value)}
                    
                >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>

                </select>
            </td>

            <td>

                <select
                    value={row['expires'] || 'No'}
                    onChange={(e) => handleInputChange(rowIndex, 'expires', e.target.value)}
                >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>

                </select>
            </td>

            {renderOptionInputs(row, rowIndex)}
            
        </tr>
    ))
)}
</tbody>

                            </table>

                            <div>
                                <div>
                                    <p className="secondary-color-paragraph">*Please note that the first five predefined questions cannot be removed. However, you may add options to each question if applicable</p>
                                </div>
                            </div>
                            
                            {errors.length > 0 && (
                                <div className="error-text">
                                    <ul>
                                        {errors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            

                            <div className="add-row-add-option-container">
                                <div>
                                    <button onClick={handleAddRow} className="confirm-button-response">Add Question</button>
                                </div>
                                <div>
                                    <button onClick={handleAddOption} className="confirm-button-response" disabled={visibleOptions >= 15}>
                                        Add Option
                                    </button>
                                </div>
                            </div>

                            {optionError && <div className="error-text">{optionError}</div>}

                            

                            <div style={{ textAlign: 'center' }}>
                                <p>By clicking 'Edit Checklist' checklist will be created.</p>
                            </div>

                            <div className="add-question-submit-clear-button-container">
                                <div>
                                    <button 
                                        onClick={handleSubmit} 
                                        className="confirm-button" 
                                        disabled={submitStatus === 'loading'}
                                    >
                                        {submitStatus === 'loading' ? (
                                            <>
                                                <FaSpinner className="fa-spin" />
                                                <span className="submitting-questions-text"> Editing Checklist...</span>
                                            </>
                                        ) : (
                                            'Edit Checklist'
                                        )}
                                    </button>
                                </div>
                                <div>
                                    <button onClick={handleClear} className="cancel-button">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                                
                            )}

                            
                            
                        </div>


                        

                        
                    </div>
                </div>
            </div>

            {showRowAdded &&
            
                <ItemAddedToaster
                    item={'Question Row Added'}
                />

            }

            {showRowRemoved &&
            
                <ItemRemovedToaster
                    item={'Question Row Deleted'}
                />

            }

            {showOptionAdded &&
            
                <ItemAddedToaster
                    item={'Option Added'}
                />

            }

            {showCategoryAdded &&

                <ItemAddedToaster
                    item={'Category Added'}
                />

            }

            {showEditedCategory && 
                <ItemAddedToaster 
                    item={'Category name edited'}
                />
            }

            {showCancelEditedCategory &&

                <ItemAddedToaster 
                    item={'Category Edit Cancelled'}
                />
            
            }

            
                

                {showSuccess && 
                    <GenericSuccessToaster
                        heading='Edited Successfully'
                        message='As we redirect you to the next page'
                    />
                }
            
            
        </div>
    );
};

export default EditChecklistToaster;
