// KBC SPC Application URl 

// Gap Analysis 
const API_BASE_URL = 'https://gapanalysis.devkbcsafety.com'; // 

//const API_BASE_URL = 'https://spcdev.devkbcsafety.com'; // staging url

//const API_BASE_URL = 'https://spcstaging.devkbcsafety.com'; // development URL 

//const API_BASE_URL = 'http://localhost:5000'; // local host

export default API_BASE_URL;